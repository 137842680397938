<template>
  <div class="mag-list">
    <el-breadcrumb class="mag-list-bread" separator="/">
      <el-breadcrumb-item :to="{path: '/User/workbench'}">我的工作台</el-breadcrumb-item>
      <el-breadcrumb-item>消息动态</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="mag-list-main">
      <div class="search clearfix">
        <el-input class="fl" v-model="search.title" style="width: 300px" placeholder="请输入内容"></el-input>
        <el-button class="fl btn" type="primary" @click="searchItem()">查询</el-button>
        <el-select class="fl" style="width: 190px" @change="searchItem()" v-model="search.staus" placeholder="请选择">
          <el-option v-for="item in stausList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <el-table @expand-change="showMsg" class="table" :data="msgData">
        <el-table-column label="状态" width="180">
          <template slot-scope="scope">
            <span :class="[scope.row.readStatus === '2' ? 'round' : '']">{{ scope.row.readStatus === '1' ? '已读' : '未读' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sendTime" label="时间" width="180"> </el-table-column>
        <el-table-column prop="msgTitle" label="消息标题"> </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <p class="article">{{ scope.row.msgContent }}</p>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="fr" background layout="prev, pager, next" :current-page="page" :total="this.total" @current-change="currentChange"> </el-pagination>
    </div>
  </div>
</template>
<script>
import {getMessgeList, updateReadStatus} from '../api.js';
export default {
  data() {
    return {
      search: {
        title: '',
        staus: ''
      },
      stausList: [
        {
          value: '',
          label: '请选择消息状态'
        },
        {
          value: '1',
          label: '已读'
        },
        {
          value: '2',
          label: '未读'
        }
      ],
      msgData: [
        {
          msgContent: 'hsnufihdsufihdu',
          readStatus: '1',
          sendTime: '2021-19',
          msgTitle: '标题'
        },
        {
          msgContent: 'hsnufihdsufihdu',
          readStatus: '1',
          sendTime: '2021-19',
          msgTitle: '标题'
        }
      ],
      page: 1,
      total: 0
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    async showMsg(row) {
      if (row.readStatus == '1') return;
      let res = await updateReadStatus({msgId: row.msgId});
      if (res.errno === 0) {
        this.msgData.forEach(item => {
          if (item.msgId === row.msgId) item.readStatus = 1;
        });
      }
    },
    searchItem() {
      this.page = 1;
      this.getTableData();
    },
    currentChange(page) {
      this.page = page;
      this.getTableData(page);
    },
    async getTableData(page) {
      if (page) this.page = page;
      let parmas = {
        customer: this.$route.query.customer,
        page: 1
      };
      parmas.page = this.page;
      parmas.msgTitle = this.search.title;
      parmas.readStatus = this.search.staus;
      let res = await getMessgeList(parmas);
      if (res.errno === 0) {
        this.msgData = res.data.list;
        this.total = res.data.total;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.mag-list {
  position: relative;
  &-bread {
    position: absolute;
    top: -38px;
    left: 0;
    font-size: 18px;
  }
  &-main {
    overflow: hidden;
    background-color: #fff;
    margin-top: 22px;
    padding: 25px;
    border-radius: 5px;
    border: 1px solid #eaf2f8;
    .search {
      margin-bottom: 30px;
      .btn {
        margin: 0 54px 0 12px;
      }
    }
    .article {
      padding: 10px;
      line-height: 20px;
      background-color: #eff6ff;
    }
    .table {
      width: 100%;
      margin-bottom: 29px;
      & /deep/ .el-table__expanded-cell {
        padding: 0;
      }
    }
  }
  .round::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 20px;
    width: 5px;
    height: 5px;
    background-color: red;
    border-radius: 50%;
  }
}
</style>
