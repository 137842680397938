var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mag-list" },
    [
      _c(
        "el-breadcrumb",
        { staticClass: "mag-list-bread", attrs: { separator: "/" } },
        [
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { path: "/User/workbench" } } },
            [_vm._v("我的工作台")]
          ),
          _c("el-breadcrumb-item", [_vm._v("消息动态")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mag-list-main" },
        [
          _c(
            "div",
            { staticClass: "search clearfix" },
            [
              _c("el-input", {
                staticClass: "fl",
                staticStyle: { width: "300px" },
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.search.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "title", $$v)
                  },
                  expression: "search.title",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "fl btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.searchItem()
                    },
                  },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-select",
                {
                  staticClass: "fl",
                  staticStyle: { width: "190px" },
                  attrs: { placeholder: "请选择" },
                  on: {
                    change: function ($event) {
                      return _vm.searchItem()
                    },
                  },
                  model: {
                    value: _vm.search.staus,
                    callback: function ($$v) {
                      _vm.$set(_vm.search, "staus", $$v)
                    },
                    expression: "search.staus",
                  },
                },
                _vm._l(_vm.stausList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "table",
              attrs: { data: _vm.msgData },
              on: { "expand-change": _vm.showMsg },
            },
            [
              _c("el-table-column", {
                attrs: { label: "状态", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            class: [
                              scope.row.readStatus === "2" ? "round" : "",
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.readStatus === "1" ? "已读" : "未读"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "sendTime", label: "时间", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "msgTitle", label: "消息标题" },
              }),
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", { staticClass: "article" }, [
                          _vm._v(_vm._s(scope.row.msgContent)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "fr",
            attrs: {
              background: "",
              layout: "prev, pager, next",
              "current-page": _vm.page,
              total: this.total,
            },
            on: { "current-change": _vm.currentChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }